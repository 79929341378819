@import "./tokens/animations.less";
@import "./tokens/borders.less";
@import "./tokens/breakpoints.less";
@import "./tokens/colors.less";
@import "./tokens/fonts.less";
@import "./tokens/links.less";
@import "./tokens/spacing.less";
@import "./tokens/shadows.less";
@import "./tokens/transitions.less";
@import "./tokens/z-index.less";
@import "./plugins/index.less";

// Inverted tokens
@inverted-text-primary-color: @grey[50];
@inverted-text-secondary-color: @grey[200];
@inverted-text-tertiary-color: @grey[400];
@inverted-background: @grey[800];
@inverted-hover-background: @grey[700];
@inverted-control-background: @grey[700];
@inverted-active-background: @grey[600];
@inverted-border-color: @grey[600];

// Text tokens
@text-strong-color: @black;
@text-primary-color: @grey[800];
@text-secondary-color: @grey[600];
@text-tertiary-color: @grey[400];
@text-accent-color: @grey[400];
@text-placeholder-color: @grey[500];
@text-label-color: @grey[500];
@text-white-color: @grey[50];

// Primary tokens
@primary-background: @grey[700];
@primary-background-hover: @grey[800];
@primary-background-active: @grey[900];

// Loading tokens
@loading-color: @primary-background;

// Disabled tokens
@disabled-opacity: 0.4;

// Action tokens
@action-primary-background: @primary-background;
@action-primary-hover-background: @primary-background-hover;
@action-primary-active-background: @primary-background-active;
@action-primary-text-color: @white;

@action-secondary-background: @white;
@action-secondary-hover-background: @grey[75];
@action-secondary-text-color: @black;
@action-secondary-border-color: @grey[200];
@action-secondary-border: 1px solid @action-secondary-border-color;
@action-secondary-hover-border-color: @grey[300];
@action-secondary-hover-border: 1px solid @action-secondary-hover-border-color;
@action-secondary-active-border-color: @black;

@action-tertiary-background: @grey[75];
@action-tertiary-hover-background: @grey[150];
@action-tertiary-text-color: @grey[600];

// Looks like @grey[75] over white, but with transparency for over gradients
@action-transparent-hover-background: fade(@grey[500], 10%);

@action-border-radius: @border-radius[s];

// Tab tokens
@tab-active-color: @primary-background;
@tab-inactive-color: @grey[500];
@tab-inactive-count-color: @grey[400];
@tab-active-line-color: @primary-background;
@tab-inactive-hover-color: @grey[600];
@tab-inactive-hover-line-color: @grey[400];

// Checkbox tokens
@checkbox-active-color: @primary-background;
@checkbox-hover-color: @grey[200];
@checkbox-disabled-color: @grey[150];
@checkbox-active-disabled-color: @grey[400];

// Divider tokens
@divider-color: @grey[150];
@divider-border: 1px solid @divider-color;
@divider-large-size: 4px;
@divider-large: @divider-large-size solid @divider-color;

// Modal tokens
@modal-background: @white;
@modal-overlay-background: fade(@grey[600], 30%);

// Image tokens
@image-border: 1px solid @transparent;
@image-hover-border: 1px solid @grey[150];
@image-background: @white;
@image-fade-outline-size: 3px;

.image-fade-outline(@size: @image-fade-outline-size) {
  outline: @size solid fade(@white, 50%);
  outline-offset: (-@size);
}

// Input tokens
@input-border-radius: @border-radius[s];
@input-background: @white;
@input-border-color: @grey[200];
@input-border: 1px solid @input-border-color;
@input-hover-background: @white;
@input-hover-border-color: @grey[300];
@input-hover-border: 1px solid @input-hover-border-color;
@input-active-background: @white;
@input-active-border-color: @grey[600];
@input-active-border: 1px solid @input-active-border-color;
@input-active-hover-background: @white;
@input-disabled-background: @grey[75];
@input-text-color: @black;
@input-outline: @primary-background;
@input-placeholder-color: @grey[400];

.input-base-reset() {
  appearance: none;
  border: none;
  background-image: none;
  background-color: @transparent;
  box-shadow: none;
  outline: none;
  width: 100%;
  padding: 0;

  &[type="number"] {
    appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
}

.input-default() {
  .transition-hover(background, border-color, box-shadow);

  background: @input-background;
  border: 1px solid @input-border-color;
  border-radius: @input-border-radius;
}

.input-hover() {
  border: 1px solid @input-hover-border-color;
}

.input-focus() {
  outline: none; // handled via the shadow
  border: 1px solid @input-active-border-color;
  box-shadow: 0 0 0 0.5px @input-active-border-color inset;
}

.input-error() {
  border: 1px solid @danger[200];
  box-shadow: 0 0 0 0.5px @danger[200] inset;

  &::placeholder {
    color: @danger[300];
  }
}

.input-error-hover() {
  border: 1px solid @danger[300];
}

.input-error-focus() {
  border: 1px solid @danger[500];
  box-shadow: 0 0 0 0.5px @danger[500] inset;
}

// Styling mixins
.hover-expand-background(@size, @background, @radius: @border-radius[s]) {
  &::after {
    transition:
      transform @transition-duration[fast]
        cubic-bezier(0.345, 0.115, 0.135, 1.42),
      opacity @transition-duration[faster] ease-out;
    opacity: 0;
    border-radius: @radius;
    background: @background;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: @size;
    height: @size;
    transform: translate(-50%, -50%) scaleX(0.7) scaleY(0.7) perspective(1px);
    z-index: @z-index[behind];
  }

  &:not(:disabled):hover {
    &::after {
      opacity: 1;
      transform: translate(-50%, -50%) scaleX(1) scaleY(1) perspective(1px);
    }
  }
}

// Scroll mixins
.hide-scrollbar() {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  &::-webkit-scrollbar {
    display: none; // Chrome & Safari
  }
}

.inline-scrollbar() {
  // Helper scroll variable
  @scroll-track-color: @transparent;
  @scroll-thumb-color: @grey[75];

  // Firefox scroll styles
  scrollbar-width: thin;
  scrollbar-color: @scroll-thumb-color @scroll-track-color;

  // Chrome/Safari/edge styles
  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: @scroll-track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @scroll-thumb-color;
    border-radius: @border-radius[s];
  }
}

.smooth-scroll() {
  // Allow for better mobile scrolling
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

// Line clamping
.line-clamp(@line-count) {
  /* @see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: @line-count;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// Keeps the same color but changes the alpha value to the desired alpha with the background color
// From https://stackoverflow.com/questions/12228548/finding-equivalent-color-with-opacity
.transparentify(@desired_color, @desired_alpha, @background_color: @white) {
  @r3: red(@desired_color);
  @g3: green(@desired_color);
  @b3: blue(@desired_color);

  @r2: red(@background_color);
  @g2: green(@background_color);
  @b2: blue(@background_color);

  // r1 = (r3 - r2 + r2 * a1) / a1
  @r1: ((@r3 - @r2 + (@r2 * @desired_alpha)) / @desired_alpha);
  @g1: ((@g3 - @g2 + (@g2 * @desired_alpha)) / @desired_alpha);
  @b1: ((@b3 - @b2 + (@b2 * @desired_alpha)) / @desired_alpha);

  background-color: @desired_color;
  background-color: rgb(@r1 @g1 @b1 / @desired_alpha);
}

// a11y
// pulled from https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
.visually-hidden() {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
